import '../styles/globals.css'
import './Home.module.css'
import 'react-notifications/lib/notifications.css';
import type { AppProps } from 'next/app';
import { store, persistor } from '../store';
import { Provider } from 'react-redux'
import {PersistGate} from "redux-persist/integration/react";
import {NotificationContainer} from 'react-notifications';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Component {...pageProps} />
        <NotificationContainer />
      </PersistGate>
    </Provider>
  );
}

export default MyApp
