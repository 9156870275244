import { createSlice } from '@reduxjs/toolkit';

export const initialUserState = {
  id: '',
  name: '',
  age: 0,
  email: '',
  token: ''
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser: (state, action) => {
      const { _id: id, name, age, email, token } = action.payload;

      state.id = id ?? state.id;
      state.name = name ?? state.name;
      state.age = age ?? state.age;
      state.email = email ?? state.email;
      state.token = token ?? state.token;
    },
  },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
